/* ./src/index.css */
#root {
  overflow: hidden;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}